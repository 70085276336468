import * as React from "react";
import {Form, Input, message, Modal, ModalProps} from "antd";
import {css} from "@emotion/css";
import jsonRequest from "../../../../utils/request/jsonRequest";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useRequest} from "ahooks";
import {getAccountContact} from "../../../../utils/api/account";

export type UpdateContactModalProps = {
  onClose: () => void;
} & Omit<ModalProps, "onCancel" | "footer">

const modal = css`
  .ant-modal-footer {
    padding-top: 0;
    padding-right: 26px;
    padding-bottom: 26px;
  }
`

const UpdateContactModal = (props: UpdateContactModalProps) => {
  const {account} = useSelector((s: any) => s)
  const [form] = Form.useForm()
  const {
    open,
    title = '联系方式',
    onOk = () => form.submit(),
    onClose = () => {},
    bodyStyle = {
      paddingTop: 0,
      paddingBottom: 0,
    },
    ...modalProps
  } = props;

  const {data, loading, run: load} = useRequest(() => {
    return getAccountContact().then(rsp => rsp.data.data)
  }, {
    manual: true,
  })

  useEffect(() => {
    if (open) {
      load()
    }
  }, [open]);

  useEffect(() => {
    console.log(account)
  }, [account]);

  useEffect(() => {
    if (form) {
      form.setFieldValue('phone', !!data?.phone ? data?.phone : account?.phone)
      form.setFieldValue('email', !!data?.email ? data?.email : account?.email)
      form.setFieldValue('socialMediaAccount', data?.socialMediaAccount ?? "")
    }
  }, [form, data, account])

  const onFinish = (values) => {
    return jsonRequest('/account/contact', {
      method: 'PUT',
      data: values,
    }).then((rsp) => {
      if (rsp.success) {
        message.success('操作成功');
        onClose();
      }
    })
  }

  return (
    <Modal
      {...{
        className: modal,
        open,
        title,
        onOk,
        bodyStyle,
        onCancel: () => {
          onClose();
        },
        confirmLoading: loading,
        ...modalProps
      }}
    >
      <Form
        {...{form, onFinish}}
        labelCol={{ span: 5 }}
        wrapperCol={{span: 19}}
      >
        <Form.Item label="手机号码" name='phone' rules={[{required: true}]}>
          <Input placeholder="填写联系您的有效手机号码(平台不对外显示)" allowClear/>
        </Form.Item>
        <Form.Item label="邮箱地址" name='email' rules={[{required: true}]}>
          <Input placeholder="E-mail" allowClear/>
        </Form.Item>
        <Form.Item label="社交账号" name='socialMediaAccount' rules={[{required: true}]}>
          <Input placeholder="填写:微信帐号:xx、QQ帐号xx、等" allowClear/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateContactModal