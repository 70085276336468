import {Input, InputProps} from "antd";
import * as React from "react";
import {useEffect, useState} from "react";

export type NumericInputProps = {
  value?: string;
  onChange?: (v: string) => void;
  fixed?: number;
} & Omit<InputProps, 'value' | 'onChange'>

const NumericInput = ({onChange, value, fixed = -1, ...props}: NumericInputProps) => {
  const [focus, setFocus] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
    }
  };

  const handleFocus = () => {
    setFocus(true)
  }

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = typeof value === 'string' ? value : String(value);

    if (valueTemp.charAt(valueTemp.length - 1) === '.' || valueTemp === '-') {
      valueTemp = valueTemp.slice(0, -1);
    }

    if (fixed >= 0) {
      valueTemp = Number(valueTemp).toFixed(fixed)
    } else {
      valueTemp = valueTemp.replace(/0*(\d+)/, '$1')
    }

    onChange(valueTemp);
    setFocus(false)
  };

  useEffect(() => {
    if (!focus) {
      handleBlur();
    }
  }, [value])

  return (<>
    <Input onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} {...{value, ...props}} />
  </>)
}

export default NumericInput
