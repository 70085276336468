import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import {
  Avatar,
  Col,
  Row,
  Form,
  Input,
  Divider,
  Space,
  Switch,
  Select,
  TimePicker, Spin, InputNumber, Radio, message, Cascader, Checkbox
} from "antd";
import * as Moment from "moment"
import styled from "@emotion/styled";
import OptionCard from "../../../components/cards/optionCard";
import DarkButton from "../../../components/buttons/darkButton";
import AvatarUpload from "./avatarUpload";
import auth from "../../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import request from "../../../utils/request";
// import jobs from "../../../utils/options/jobs";
import workStatus from "../../../utils/options/workStatus";
import workTypes from "../../../utils/options/workTypes";
import NumericInput from "../../../components/form/NumericInput";
import Seo from "../../../components/seo";
import {useRequest} from "ahooks";
import {getAccountContact, getResumeSetting} from "../../../utils/api/account";
import { DefaultOptionType } from "antd/lib/cascader";
const {Item: FormItem} = Form
import {EditOutlined} from "@ant-design/icons";
import UpdateContactModal from "../../../components/pages/personal/resume/UpdateContactModal";
import jsonRequest from "../../../utils/request/jsonRequest";

const AvatarDesc = styled.div`
  line-height: 20px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.4);
`

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
`

const TitleDesc = styled.span`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const AddressSelects = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
  .ant-select-selector {
    border: none !important;
    padding: 0 !important;
  }
  >div {
    flex: 1;
    margin: -1px 0;
    &:first-child {
      .ant-select-selection-item {
        padding-left: 12px;
      }
    }
    .ant-select-selection-item {
      padding-right: 16px !important;
    }
    .ant-select-arrow {
      right: 4px;
    }
  }
`

const CheckboxText = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
`

const InputSuffix = styled.span`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const SwitchLabel = styled.span`
  line-height: 22px;
  font-size: 14px;
  font-family: Roboto-Regular, Roboto,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const ContactStatus = styled.span`
  color: rgba(42, 130, 228, 0.8);
  &.none {
    color: rgba(212, 48, 48, 1);
  }
  &:hover {
    cursor: pointer;
  }
`

const initialValues = {
  regionId: 1
}

const PersonalInfo = () => {
  auth.authorize()
  const dispatch = useDispatch()
  const {account} = useSelector((s: any) => s)
  const [form] = Form.useForm()
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [jobs, setJobs] = useState([])
  const [regions, setRegions] = useState([])
  const [loadingProvinces, setLoadingProvinces] = useState(false)
  const [resume, setResume] = useState(null)
  const [loadingResume, setLoadingResume] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isShowContactModal, setIsShowContactModal] = useState(false)
  const [selectedRegionId, setSelectedRegionId] = useState(initialValues.regionId)
  const {data: resumeSetting, loading: loadingResumeSetting, run: loadResumeSetting} = useRequest(() => {
    return getResumeSetting().then(rsp => rsp.data.data)
  })
  const {data: contact, loading: loadingContact, run: loadContact} = useRequest(() => {
    return getAccountContact().then(rsp => rsp.data.data)
  })
  const loading = useMemo(() => loadingProvinces || loadingResume || loadingResumeSetting, [loadingProvinces, loadingResume, loadingResumeSetting])
  const loadResume = () => {
    if (loadingResume) {
      return;
    }
    setLoadingResume(true)
    request("/account/resume").then(rsp => {
      rsp.json().then(({data}) => {
        setResume(data)
      }).catch(console.error)
    }).catch(console.error).finally(() => setLoadingResume(false))
  }

  const addressOptions = useMemo(() => {
    return regions.map(region => {
      let children = []
      if (region.acronym === 'CN') {
        children = provinces.map(province => {
          return {
            label: province.name,
            value: province.code,
            children: province.cities.map(city => ({
              label: city.name,
              value: city.code,
            }))
          }
        })
      }
      return {
        value: region.id,
        label: region.name,
        children,
      };
    })
    return []
  }, [regions, provinces])

  const avatar = useMemo(() => {
    return account?.avatar;
  }, [account])

  useEffect(() => {
    if (account != null) {
      form.setFields([
        {name: 'nickname', value: account.nickname},
        // {name: 'isShowResume', value: account.isShowResume},
        // {name: 'isShowContact', value: account.isShowContact},
        // {name: 'profession'}
      ])
      setLoadingProvinces(true)
      request("/account/resume/selectOption").then(rsp => {
        rsp.json().then(({data}) => {
          setProvinces(data.provinces ?? [])
          setJobs(data.jobs ?? [])
          setRegions(data.regions ?? [])
        }).catch(console.error)
      }).catch(console.error).finally(() => {
        setLoadingProvinces(false)
      })
      loadResume()
    }
  }, [account?.id])

  useEffect(() => {
    if (resume != null) {
      form.setFieldValue("provinceCode", resume.provinceCode)
      handleProvinceChange(resume.provinceCode)
      form.setFieldValue("cityCode", resume.cityCode)
    }
  }, [provinces, resume])
  useEffect(() => {
    if (!!resumeSetting) {
      form.setFields([
        {name: 'isShowResume', value: resumeSetting.isShowResume},
        {name: 'isShowContact', value: resumeSetting.isShowContact},
      ])
    }
  }, [resumeSetting?.isShowResume, resumeSetting?.isShowContact])
  useEffect(() => {
    if (resume != null) {
      [
        'workStatus', 'title', 'job', 'workYear',
        'jobId', 'regionId',
        // 'workDayStartTime', 'workDayEndTime',
        // 'saturdayStartTime', 'saturdayEndTime', 'sundayStartTime', 'sundayEndTime',
        'workType', 'remoteWage', 'siteWage', 'isWorkDay', 'isSaturday', 'isSunday', 'isSite', 'isRemote',
      ].forEach(key => {
        form.setFieldValue(key, resume[key])
      })
      setSelectedRegionId(resume.regionId)
      form.setFieldValue('address', [resume.regionId, resume.provinceCode, resume.cityCode])

      if (provinces != null) {
        form.setFieldValue("provinceCode", resume.provinceCode)
        handleProvinceChange(resume.provinceCode)
        form.setFieldValue("cityCode", resume.cityCode)
      }

      [
        'workDay',
        'saturday', 'sunday',
      ].forEach(key => {
        form.setFieldValue(key, [
          Moment(`${resume[`${key}StartTime`]}`.padStart(4, "0"), "HHmm"),
          Moment(`${resume[`${key}EndTime`]}`.padStart(4, "0"), "HHmm"),
        ])
      })
    }
  }, [resume])

  const onValuesChange = (value, values) => {
    if (value.regionId) {
      setSelectedRegionId(value.regionId)
    }
  }

  const handleProvinceChange = (v) => {
    for (let i = provinces.length - 1; i >= 0; i--) {
      const province = provinces[i];
      if (province.code == v) {
        setCities(province.cities)
        form.setFieldValue("cityCode", province.cities?.length > 0 ? province.cities[0].code : null);
        return;
      }
    }
    setCities([])
  }
  const handleSubmit = (values: any) => {
    if (submitting) {
      return;
    }
    if (!resumeSetting?.contactAt) {
      message.warning({
        content: '请填写联系方式',
      })
      setIsShowContactModal(true);
      return;
    }
    setSubmitting(true);
    const body = new FormData()
    const keys = Object.keys(values)
    for (let i = keys.length - 1; i >= 0; i--) {
      body.set(keys[i], values[keys[i]])
    }

    ['isWorkDay', 'isSaturday', 'isSunday', 'isSite', 'isRemote'].forEach(k => {
      body.set(k, values[k] ? '1' : '0')
    })
    if (values.address) {
      if (values.address.length > 0) {
        body.set('regionId', values.address[0])
      }
      if (values.address.length >= 3) {
        body.set('provinceCode', values.address[1])
        body.set('cityCode', values.address[2])
      } else {
        body.set('provinceCode', '')
        body.set('cityCode', '')
      }
    }


    ['workDay', 'saturday', 'sunday',].forEach(key => {
      body.set(`${key}StartTime`, values[key][0].format("HHmm"))
      body.set(`${key}EndTime`, values[key][1].format("HHmm"))
    })


    jsonRequest('/account/resume', {
      method: 'PUT',
      body,
    }).then((rsp) => {
      if (!rsp.success) {
        message.warning(rsp.message)
        if (rsp.data.code == 1002) {
          setIsShowContactModal(true)
        }
        return;
      }
      message.success('保存成功');
      loadResume()
      auth.load(dispatch)
    }).catch(console.error).finally(() => {
      setSubmitting(false)
    })
  }
  return (
    <>
    <Form onFinish={handleSubmit} {...{form, onValuesChange}}>
    <PersonalLayout selected='resume' loading={loading}>
      <PersonalBody title='基础信息' backLink='/personal/resume' paddingTop={28}>
        <Row>
          <Col span={3} ><Avatar src={`${account?.avatar}?id=${new Date().getTime()}`} size={60} /></Col>
          <Col span={21} style={{display: "flex", alignItems: 'center'}}>
            <AvatarUpload />
          </Col>
          <Col span={3}></Col>
          <Col span={21}>
            <AvatarDesc>头像大小: 小于2MB; 尺寸: 200*200px; 格式: JPG/PNG</AvatarDesc>
          </Col>
        </Row>

        <Row style={{marginTop: 26}}>
          <Col span={12}>
            <Form.Item required={false} rules={[{required: true, message: '请输入昵称'}, {max: 30}]} label='个人昵称' name='nickname' labelCol={{span: 6}} wrapperCol={{span: 16}}>
              <Input placeholder='例如：前百度产品经理'/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='职业状态' name='workStatus' required={false} rules={[{required: true, message: '选择职业状态'}]} labelCol={{span: 6}} wrapperCol={{span: 16}}>
              <Select
                placeholder='请选择'
                options={Object.keys(workStatus).map(key => {
                  const item = workStatus[key]
                  return ({
                    label: item,
                    value: key,
                  })
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormItem label='工作职称' name='title' required={false} rules={[{required: true, message: '请输入工作职称'}, {max: 30}]} labelCol={{span: 6}} wrapperCol={{span: 16}}>
              <Input placeholder='例如：高级产品经理' />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label='专业岗位' name='jobId' required={false} rules={[{required: true, message: '请选择专业岗位'}]} labelCol={{span: 6}} wrapperCol={{span: 16}}>
              <Select
                placeholder='请选择'
                options={jobs.map(job => ({
                  label: job.name,
                  value: job.id,
                }))}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <Form.Item
              label='工作地区'
              name='address'
              required={false}
              labelCol={{span: 6}}
              wrapperCol={{span: 16}}
              rules={[
                {
                  validator: (rule, value, error) => {
                    if (!value[0] || (value[0] === 1 && !value[2])) {
                      return Promise.reject(new Error());
                    }
                    return Promise.resolve()
                  }, message: '请选择${label}'}]}
            >
              <Cascader
                options={addressOptions}
                displayRender={(labels: string[], selectedOptions: DefaultOptionType[]) =>
                  labels.map((label, i) => {
                    const option = selectedOptions[i];
                    return option ? <span key={`${i}${option.value}`}>{i > 0 && '/'} {label}  </span> : null;
                  })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='工作年限' labelCol={{span: 6}} wrapperCol={{span: 16}}>
              <Form.Item name='workYear' required={false} rules={[{required: true, message: '请输入工作年限'}, {max: 2, message: '工作年限最大为99'}]} noStyle>
                <NumericInput
                  fixed={0}
                  suffix={<InputSuffix>年工作经验</InputSuffix>}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{marginTop: 0}} />
        <div>
          <Title>工作时间</Title>
          <Row>
            <Col span={7} sm={10}>
              <FormItem
                label='工作日'
                name='workDay'
                required={false}
                rules={[{required: true, message: '请选择时间'}]}
                labelCol={{span: 7}}
                wrapperCol={{span: 17}}
              >
                <TimePicker.RangePicker format={'HH:mm'}/>
              </FormItem>
            </Col>
            <Col span={12} style={{paddingLeft: 12}}>
              <Row>
                <Col span={24} sm={0} style={{height: 40}}> </Col>
                <Col span={24}>
                  <FormItem name='isWorkDay' valuePropName='checked' >
                    <Switch checkedChildren='工作' unCheckedChildren='休息'/>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={7} sm={10}>
              <FormItem label='星期六' name='saturday' required={false} rules={[{required: true, message: '请选择时间'}]} labelCol={{span: 7}} wrapperCol={{span: 17}}>
                <TimePicker.RangePicker format={'HH:mm'}/>
              </FormItem>
            </Col>
            <Col span={12} style={{paddingLeft: 12}}>
              <Row>
                <Col span={24} sm={0} style={{height: 40}}> </Col>
                <Col span={24}>
                  <FormItem name='isSaturday' valuePropName='checked' >
                    <Switch checkedChildren='工作' unCheckedChildren='休息'/>
                  </FormItem>
                </Col>
              </Row>
            </Col>

          </Row>
          <Row>
            <Col span={7} sm={10}>
              <FormItem label='星期天' name='sunday' required={false} rules={[{required: true, message: '请选择时间'}]} labelCol={{span: 7}} wrapperCol={{span: 17}}>
                <TimePicker.RangePicker format={'HH:mm'}/>
              </FormItem>
            </Col>
            <Col span={12} style={{paddingLeft: 12}}>
              <Row>
                <Col span={24} sm={0} style={{height: 40}}> </Col>
                <Col span={24}>
                  <FormItem name='isSunday' valuePropName='checked' >
                    <Switch checkedChildren='工作' unCheckedChildren='休息'/>
                  </FormItem>
                </Col>
              </Row>
            </Col>

          </Row>
        </div>
        <Divider style={{marginTop: 0}} />
        <div>
          <Title>工作方式 <TitleDesc>（以什么工作方式接项目）</TitleDesc></Title>
          <Form.Item name='workType' noStyle>
            <Radio.Group style={{width: '100%'}}>
              <Row>
                <Col span={3}></Col>
                {
                  Object.keys(workTypes).map(key => (
                    <Col span={5} key={key}>
                      <Radio value={key}> <CheckboxText>{workTypes[key]}</CheckboxText></Radio>
                    </Col>
                  ))
                }
              </Row>
            </Radio.Group>
          </Form.Item>
          <Title>日薪报价 <TitleDesc>（按7小时/天工作时间）</TitleDesc></Title>

          <Row>
            <Col span={7} sm={10}>
              <FormItem label='远程工作' labelCol={{span: 7}} wrapperCol={{span: 17}}>
                <Form.Item name='remoteWage' required={false} rules={[{required: true, message: '请输入'}, {max: 5, message: '最大金额为99999'}]} noStyle>
                  <NumericInput fixed={0} suffix={<InputSuffix>元/人天（税前收入）</InputSuffix>}/>
                </Form.Item>
              </FormItem>
            </Col>
            <Col span={12} style={{paddingLeft: 12}}>
              <Row>
                <Col span={24} sm={0} style={{height: 40}}> </Col>
                <Col span={24}>
                  <FormItem name='isRemote' valuePropName='checked' >
                    <Switch checkedChildren='打开' unCheckedChildren='拒绝'/>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={7} sm={10}>
              <FormItem label='驻场工作' labelCol={{span: 7}} wrapperCol={{span: 17}}>
                <Form.Item name='siteWage' rules={[{required: true, message: '请输入'}, {max: 5, message: '最大金额为99999'}]} noStyle>
                  <NumericInput fixed={0} suffix={<InputSuffix>元/人天（税前收入）</InputSuffix>}/>
                </Form.Item>
              </FormItem>
            </Col>
            <Col span={12} style={{paddingLeft: 12}}>
              <Row>
                <Col span={24} sm={0} style={{height: 40}}> </Col>
                <Col span={24}>
                  <FormItem name='isSite' valuePropName='checked' >
                    <Switch checkedChildren='打开' unCheckedChildren='拒绝'/>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Divider style={{marginTop: 0}}/>
        <Title>其他设置</Title>
        <Space size={12} direction='vertical' style={{width: '100%'}}>
          <OptionCard
            title={"履历展示"}
            desc='选择关闭后，用户不能查看您的履历详情'
            padding={16}
            options={(
              <Space size={6}>
                <SwitchLabel>关/开</SwitchLabel>
                <Form.Item name='isShowResume' valuePropName='checked' noStyle>
                  <Switch />
                </Form.Item>
              </Space>
            )}
          />

          <OptionCard
            title={(
              <Space>
                <span>联系方式</span>
                <ContactStatus
                  className={resumeSetting?.contactAt ?? 'none'}
                  onClick={() => setIsShowContactModal(true)}
                >
                  {resumeSetting?.contactAt ? '修改' : '未填写'}<EditOutlined/>
                </ContactStatus>
              </Space>
            )}
            desc='选择打开后，在平台完成履历认证的用户可以查看您的微信号和邮箱地址'
            padding={16}
            options={(
              <Space size={6}>
                <SwitchLabel>关/开</SwitchLabel>
                <Form.Item name='isShowContact' valuePropName='checked' noStyle>
                  <Switch />
                </Form.Item>
              </Space>
            )}
          />
        </Space>
        <div style={{marginTop: 32, textAlign: 'right'}}>
          <DarkButton onClick={form.submit} loading={submitting}>确认保存</DarkButton>
        </div>
      </PersonalBody>
    </PersonalLayout>
  </Form>
  <UpdateContactModal
    open={isShowContactModal}
    onClose={() => {
      setIsShowContactModal(false);
      loadResumeSetting();
      loadContact();
    }}
  />
  </>
  )
}

export default PersonalInfo

export const Head = () => <Seo />
